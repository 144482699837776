
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models/index'

@Component({
  name: 'Brands',
  components: {
    BrandsHeader: () => import('./components/BrandsHeader.vue'),
    BrandCard: () => import('./components/BrandCard.vue')
  }
})
export default class extends Vue {
  private giftCards: GiftCard[] = []
  private giftCardsLoading = true
  private totalGiftCards = 0

  private listQuery: any = {
    giftCardName: '',
    page: 1,
    per: 20
  }

  created(): void {
    this.getGiftCards()
  }

  private async getGiftCards(): Promise<void> {
    this.giftCardsLoading = true

    const { data, meta } =
        await GiftCard
            .where({ name: { prefix: this.listQuery.giftCardName } })
            .select(['name', 'id', 'code', 'logo', 'logoSquare', 'logoPrimaryColor'])
            .selectExtra(['buyerRate'])
            .page(this.listQuery.page)
            .per(this.listQuery.per)
            .order({ name: 'asc' })
            .stats({ total: 'count' })
            .all()

    this.giftCards.push(...data)
    this.totalGiftCards = meta.stats.total.count
    this.listQuery.page += 1
    this.giftCardsLoading = false
  }

  private handleSearch(): void {
    this.giftCards = []
    this.listQuery.page = 1
    this.totalGiftCards = 0
    this.getGiftCards()
  }

  private refresh(): void {
    this.giftCards = []
    this.totalGiftCards = 0
    this.listQuery = {
      giftCardName: '',
      page: 1,
      per: 10
    }
    this.getGiftCards()
  }
}
