
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models/index'

import { clipboardSuccess } from '@/utils/clipboard'
import { useUserStore } from "@/stores/user";

@Component({
  name: 'BrandCard'
})
export default class extends Vue {
  @Prop({ required: true, default: null }) private giftCard!: GiftCard

  userStore: any = useUserStore()

  clipboardSuccess = clipboardSuccess

  get isBuyer() {
    return this.userStore.roles.includes('buyer')
  }
}
