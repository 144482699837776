
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard, Sku } from '@/models/index'

import { clipboardSuccess } from '@/utils/clipboard'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'GiftCardView'
})
export default class extends Vue {
  userStore: any = useUserStore()

  private giftCard: GiftCard = new GiftCard()
  private giftCardLoading = true

  private sortedSkus: Sku[] = []
  private tableKey = 0

  private clipboardSuccess = clipboardSuccess

  get isBuyer() {
    return this.userStore.roles.includes('buyer')
  }

  async created() {
    await this.getGiftCardById()
  }

  truncateID(id: string): string {
    return `${id.slice(0, 4)} .... ${id.substr(id.length - 4)}`
  }

  private async getGiftCardById() {
    const { data } = await GiftCard
      .includes('skus')
      .selectExtra(['apiEnabled', 'buyerRate'])
      .find(this.$route.params.id)

    this.giftCard = data
    this.sortedSkus = data.skus.slice().filter((sku: Sku) => !sku.discardedAt).sort((a, b) => a.faceValue - b.faceValue)

    this.giftCardLoading = false
  }

  private formatValidityPeriod(days: number) {
    const currentDate = this.$moment(new Date(), 'YYYY-MM-DD')
    const futureDate = this.$moment(currentDate).add(days, 'days')
    const diff = this.$moment.duration(futureDate.diff(currentDate))

    const years = diff.years() <= 0 ? '' : this.$tc('brands.view.period.year', diff.years() - 1, { year: diff.years() })
    const months = diff.months() <= 0 ? '' : this.$tc('brands.view.period.month', diff.months() - 1, { month: diff.months() })

    return `${years} ${years && months ? this.$t('brands.view.period.separator') : ''} ${months}`
  }
}
